body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100vh;
}

#root {
  height: 100%;
}

.bg-brand-green {
  background-color: #038066;
}

.hover\:bg-brand-green:hover {
  background-color: #038066;
}

.border-brand-green {
  border-color: #038066;
}

.text-brand-green {
  color: #038066;
}

.bg-brand-yellow {
  background-color: #fab836;
}

.hover\:bg-brand-yellow:hover {
  background-color: #fab836;
}

.border-brand-yellow {
  border-color: #fab836;
}

.text-brand-yellow {
  color: #fab836;
}

.hover\:text-brand-yellow:hover {
  color: #fab836;
}

/* https://tailwindcomponents.com/component/windows-10-fluent-design-progress-bar */
.fluentProgressBar-normal {
  background-color: #fab836;
  height: 4px;
}

.fluentProgressBar-waiting {
  background: rgba(0, 120, 212, 0);
  background: -moz-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(0, 120, 212, 1) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 120, 212, 0)),
    color-stop(51%, rgba(0, 120, 212, 1)),
    color-stop(100%, rgba(0, 120, 212, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(0, 120, 212, 1) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(0, 120, 212, 1) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(0, 120, 212, 0) 0%,
    rgba(0, 120, 212, 1) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 120, 212, 0) 0%,
    rgba(0, 120, 212, 1) 51%,
    rgba(0, 120, 212, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0078d4', endColorstr='#0078d4', GradientType=1 );

  height: 4px;

  -webkit-animation: progressBarAnimation 2s linear infinite;
  animation: progressBarAnimation 2s linear infinite;
}

@keyframes progressBarAnimation {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
